import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Header/Header.js'
import '@isceco/widget-library2/basic-elements/LanguageSwitcher/LanguageSwitcher.js'
import '@isceco/widget-library2/basic-elements/ActionMenu/ActionMenu.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import WebComponent from '../../WebComponent.js'


export default class Header extends WebComponent {

  get translationFile() {
    return './views/Header/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }


  get css() {
    return css`
      .user-language {
        display: flex;
        cursor: pointer;
        align-items: end;
        flex-direction: row;
        gap: var(--isceco-space-200);
      }
    `
  }

  getTemplate() {
    return html`
      <isceco-header text="${this.i18n.translate('header.blv')}" shrink>
        <div class="user-language">
          <div class="user-menu">
            <isceco-action-menu id="user-action-menu"
                                .items="${[
                                  {
                                    icon: 'sign out', text: this.i18n.translate('header.logout'), callback: () => {
                                      window.keycloak.logout()
                                    }
                                  }
                                ]}">
              <isceco-link slot="wrapper"
                           text="${this.displayName()}"
                           icon="user circle outline"
              </isceco-link>
            </isceco-action-menu>
          </div>
          <isceco-language style="display: flex"></isceco-language>
        </div>
      </isceco-header>
    `
  }

  displayName = () => {
    if (window.keycloak !== undefined) {
      return `${window.keycloak.tokenParsed.given_name} ${window.keycloak.tokenParsed.family_name}`
    } else {
      return ''
    }
  }
}

customElements.define('eltra-management-header', Header)
