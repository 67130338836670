import ExceptionService from './ExceptionService.js'
import ApplicationException from './ApplicationException'

export default class BaseService {

  constructor(relativeBase) {
    this.backendUrl = ''
    this.relativeBase = relativeBase
    this.logging = new ExceptionService()
  }

  retrieveConfiguration() {
    return fetch('./config/config.json', {cache: 'reload'})
      .then(res => res.json())
  }

  retrieveBackendUrl() {
    return this.retrieveConfiguration()
      .then(config => {
        this.backendUrl = `${config.backendUrl}/${this.relativeBase}`
      })
      .catch(error => {
        this.logging.log(error)
      })
  }

  async getBackendListUrl(serviceName) {
    await this.retrieveBackendUrl()
    return serviceName ? `${this.backendUrl}/${serviceName}` : this.backendUrl
  }

  async list(serviceName) {
    await this.getBackendListUrl(serviceName).then(url => this.serviceurl = url)
    return this.checkError(
      fetch(this.serviceurl, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async create(object, commandSuffix = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(this.backendUrl + commandSuffix, {
        method: 'POST',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(object)
      })
    )
  }

  async read(id, subResource = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${id}/${subResource}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async update(object, serviceName) {
    await this.retrieveBackendUrl()
    const url = (serviceName) ? `${this.backendUrl}/${serviceName}` : this.backendUrl
    return this.checkError(
      fetch(`${url}/${object.id}`, {
        method: 'PUT',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(object)
      })
    )
  }

  async delete(id) {
    const _headers = {'locale': getLanguage()}
    if (!isEmpty(window.keycloak)) {
      _headers['Authorization'] = `Bearer ${window.keycloak.token}`
    }

    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${id}`, {
        method: 'DELETE',
        cache: 'reload',
        headers: _headers
      })
    )
  }

  async checkError(promise) {
    return promise
      .then(result => {
        if (result.ok) {
          return result.json()
        } else {
          result.json().then(err => showAlert(err.title, err.detail, 'error'))
          throw new ApplicationException()
        }
      })
      .catch(error => {
        if (error instanceof ApplicationException) {
          throw error
        } else {
          showAlert('Service unavailable', 'Request to backend could not be sent', 'error')
          this.logging.log(error)
        }
      })
  }

  getJsonHeader() {
    const _headers = {
      'Content-Type': 'application/json',
      'locale': getLanguage()
    }
    if (!isEmpty(window.keycloak)) {
      _headers['Authorization'] = `Bearer ${window.keycloak.token}`
    }

    return _headers
  }
}
