export default class ModuleEnum {
  // @alican Object freeze und als const
  static KEYS() {
    return {
      traces: 0,
      inpec: 1,
      paket: 2,
      '': 3
    }
  }

  static ITEMS() {
    return Object.entries(ModuleEnum.KEYS())
      .map(([key, value]) => ({
        value: key,
        name: `module.${value}`
      }))
  }

  static FILTER_ITEMS() {
    return Object.entries(ModuleEnum.KEYS())
      .map(([key, value]) => ({
        value: isEmpty(key) ? 'none' : key,
        name: `module.${value}`
      }))
  }

  static FILTER_ITEMS_NON_EMPTY() {
    return Object.entries(ModuleEnum.KEYS())
      .filter(([key, _]) => !isEmpty(key))
      .map(([key, value]) => ({
        value: isEmpty(key) ? 'none' : key,
        name: `module.${value}`
      }))
  }
}
