export default class ApprovalRowVariant {
  get(row) {
    if (row.approval === 'REJECTED') {
      return 'error clickable'
    }
    if (row.approval === 'CANCELLED') {
      return 'warning clickable'
    }
    if (row.approval === 'ACCEPTED') {
      if (row.ruleErrors) {
        return 'warning clickable'
      } else {
        return 'success clickable'
      }
    }
    return ''
  }
}
