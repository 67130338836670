import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import AbfragenService from '../Overview/AbfragenService.js'
import ApprovalTypeEnum from '../Overview/ApprovalTypeEnum.js'
import ApprovalEnum from '../Overview/ApprovalEnum.js'
import ModuleEnum from '../Overview/ModuleEnum.js'

export default class Detail extends WebComponent {

  get translationFile() {
    return './views/Detail/i18n.json'
  }

  get css() {
    return css`
      .detail-content {
        max-width: 1260px;
        margin: 0 auto;
      }
      
      .detail-flex {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: var(--isceco-space-300);
      }

      .detail-flex > * {
        flex: 1;
      }

      .pointer {
        cursor: pointer;
      }

      .column > * {
        padding: var(--isceco-space-100) 0;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.reload()
  }

  reload() {
    const params = getNavigationParams()
    const abfragenService = new AbfragenService()

    abfragenService.create({
      requestId: params.get('requestId'),
      responseId: params.get('responseId'),
      goodsItemId: params.get('goodsItemId'),
      permitId: params.get('permitId')
    })
      .then(json => {
        this.request = json.request
        this.response = json.response
        this.goodsItem = json.goodsItem
        this.permit = json.permit
        this.ruleErrors = json.ruleError
        this.render()
      })
  }

  getTemplate() {
    return html`
      <div class="detail-content">
        <isceco-link icon="angle left"
                     text="${this.i18n.translate('page.back')}"
                     class="pointer"
                     @click=${_ => history.back()}
        ></isceco-link>

        <isceco-title text="${this.i18n.translate('page.title')}"></isceco-title>

        <div class="detail-flex">
          <div class="column">
            ${this.getRequestTemplate()}
          </div>
          <div class="column">
            ${this.getResponseTemplate()}
            ${this.getGoodsItemTemplate()}
            ${this.getPermitTemplate()}
            ${this.getRuleErrorListTemplate()}
          </div>
        </div>
      </div>
    `
  }

  getRequestTemplate() {
    if (this.request === undefined) {
      return html``
    }
    return html`
      <isceco-title text="${this.i18n.translate('request.title')}" size="medium"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.canton')}"
                         value="${this.request.canton}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.dispatchCountryCode')}"
                         value="${this.request.dispatchCountryCode}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.msgNumber')}"
                         value="${this.request.msgNumber}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.customsOfficeNumber')}"
                         value="${this.request.customsOfficeNumber}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.transportMode')}"
                         value="${this.request.transportMode}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.transactionTime')}"
                         value="${formatDateTime(this.request.transactionTime)}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.selection')}"
                         value="${this.request.selection}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.correction')}"
                         value="${this.request.correction}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.traderDeclarationNumber')}"
                         value="${this.request.traderDeclarationNumber}"></isceco-text-input>

      <isceco-title text="${this.i18n.translate('request.consignee.title')}" size="small"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.consignee.name')}"
                         value="${this.request.consigneeName}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.consignee.street')}"
                         value="${this.request.consigneeStreet}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.consignee.postalCode')}"
                         value="${this.request.consigneePostalCode}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.consignee.city')}"
                         value="${this.request.consigneeCity}"></isceco-text-input>

      <isceco-title text="${this.i18n.translate('request.declarant.title')}" size="small"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.declarant.traderIdentificationNumber')}"
                         value="${this.request.declarantTraderIdentificationNumber}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.declarant.name')}"
                         value="${this.request.declarantName}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.declarant.street')}"
                         value="${this.request.declarantStreet}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.declarant.postalCode')}"
                         value="${this.request.declarantPostalCode}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('request.declarant.city')}"
                         value="${this.request.declarantCity}"></isceco-text-input>
    `
  }

  getResponseTemplate() {
    if (this.response === undefined) {
      return html``
    }
    return html`
      <isceco-title text="${this.i18n.translate('response.title')}" size="medium"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('response.transactionTime')}"
                         value="${formatDateTime(this.response.transactionTime)}"></isceco-text-input>
      <isceco-dropdown readonly
                       .i18n="${this.i18n}"
                       .items="${ApprovalTypeEnum.ITEMS()}"
                       label="${this.i18n.translate('response.approvalType')}"
                       value="${this.response.approvalType}"></isceco-dropdown>
      <isceco-dropdown readonly
                       .i18n="${this.i18n}"
                       .items="${ApprovalEnum.ITEMS()}"
                       label="${this.i18n.translate('response.approval')}"
                       value="${this.response.approval}"></isceco-dropdown>
      <isceco-textarea readonly
                       label="${this.i18n.translate('response.infoLog')}"
                       value="${this.response.infoLog}"></isceco-textarea>
    `
  }

  getGoodsItemTemplate() {
    if (this.goodsItem === undefined) {
      return html``
    }
    return html`
      <isceco-title text="${this.i18n.translate('goodsItem.title')}" size="medium"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('goodsItem.originCountry')}"
                         value="${this.goodsItem.originCountry}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('goodsItem.commodityCode')}"
                         value="${this.goodsItem.commodityCode}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('goodsItem.description')}"
                         value="${this.goodsItem.description}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('goodsItem.netMass')}"
                         value="${isEmpty(this.goodsItem.netMass) ? '-' : this.goodsItem.netMass.toFixed(3)}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('goodsItem.additionalUnit')}"
                         value="${isEmpty(this.goodsItem.additionalUnit) ? '-' : this.goodsItem.additionalUnit.toFixed(1)}"></isceco-text-input>
      <isceco-dropdown readonly
                       .i18n="${this.i18n}"
                       .items="${ModuleEnum.ITEMS()}"
                       label="${this.i18n.translate('goodsItem.module')}"
                       value="${this.goodsItem.module}"></isceco-dropdown>
    `
  }

  getPermitTemplate() {
    if (this.permit === undefined) {
      return html``
    }
    return html`
      <isceco-title text="${this.i18n.translate('permit.title')}" size="medium"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('permit.number')}"
                         value="${this.permit.permitNumber}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('permit.type')}"
                         value="${this.permit.permitType}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('permit.authority')}"
                         value="${this.permit.permitAuthority}"></isceco-text-input>
    `
  }

  getRuleErrorListTemplate() {
    if (this.ruleErrors === undefined || this.ruleErrors.length === 0) {
      return html``
    }
    return html`
      <isceco-title text="${this.i18n.translate('ruleError.list.title')}" size="medium"></isceco-title>
      ${this.ruleErrors.map(error => this.getRuleErrorTemplate(error))}
    `
  }

  getRuleErrorTemplate(ruleError) {
    return html`
      <isceco-title text="${this.i18n.translate('ruleError.title')}" size="small"></isceco-title>
      <isceco-text-input readonly
                         label="${this.i18n.translate('ruleError.name')}"
                         value="${ruleError.name}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('ruleError.description')}"
                         value="${ruleError.description}"></isceco-text-input>
      <isceco-text-input readonly
                         label="${this.i18n.translate('ruleError.referencedElement')}"
                         value="${ruleError.referencedElement}"></isceco-text-input>
    `
  }
}
customElements.define('eltra-management-detail', Detail)
