import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/HorizontalNavigation/HorizontalNavigation.js'
import WebComponent from '../../WebComponent.js'
import Navigation from './Navigation.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'


export default class HorizontalNavigation extends WebComponent {

  get translationFile() {
    return './views/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => {
      this.reload()
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  getTemplate() {
    const allItems = [
      {text: this.i18n.translate('navigation.overview'), url: 'Overview', active: true},
      {text: this.i18n.translate('navigation.module'), url: 'Module'},
      {text: this.i18n.translate('navigation.logSettings'), url: 'LogSettings', active: true}
    ]

    const allowedItems = allItems.filter(a => new AuthorizationFilter().viewsAllowed(allItems.map(item => item.url)).indexOf(a.url) >= 0)
    return html`
      <isceco-horizontal-navigation
        .items="${allowedItems}">
      </isceco-horizontal-navigation>
    `
  }
}
customElements.define('eltra-management-horizontal-navigation', HorizontalNavigation)
