fetch('./config/config.json', {cache: 'reload'})
  .then(res => res.json())
  .then(config => {
    import(/* @vite-ignore */config.ssoKeycloakAdapter)
      .then(() => {
        // executed after the keycloack adapter script is loaded
        window.keycloak = new Keycloak({
          url: config.ssoUrl,
          realm: config.ssoRealm,
          clientId: config.ssoClient
        })
        window.keycloak.init({
          onLoad: 'login-required',
          promiseType: 'native',
          checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then(_ => {
          document.querySelector('body').innerHTML = `
<main>
  <div id="mainheader">
    <eltra-management-header></eltra-management-header>
    <eltra-management-horizontal-navigation></eltra-management-horizontal-navigation>
  </div>
  <div id="maincontent">
    <isceco-message lifetime="10" id="messagecontent"></isceco-message>
    <eltra-management-main-view></eltra-management-main-view>
    <isceco-scroll-top></isceco-scroll-top>
  </div>
  <div id="mainfooter">
    <eltra-management-footer></eltra-management-footer>
  </div>
</main>
          `
        })
      })
  })
