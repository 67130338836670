import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Switch/Switch.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import ModuleService from './ModuleService.js'
import ModuleHistoryColumns from './ModuleHistoryColumns.js'
import ModuleHistoryDTO from './ModuleHistoryDTO.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/CheckboxFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/AllFiltersReset.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import ModuleEnum from '../Overview/ModuleEnum.js'

export default class Module extends WebComponent {

  get translationFile() {
    return './views/Module/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.paketActive = false
    this.inpecActive = false
    this.tracesActive = false

    this.updateTableEvent = 'update-module-history-table'

    this.reload()
  }

  reload() {
    const moduleService = new ModuleService()

    Promise.all([moduleService.getBackendListUrl('history'), moduleService.list()])
      .then(([url, json]) => {
        this.url = url
        json.forEach(entry => {
          if (entry.name === 'paket') {
            this.paketActive = entry.active
          } else if (entry.name === 'inpec') {
            this.inpecActive = entry.active
          } else if (entry.name === 'traces') {
            this.tracesActive = entry.active
          }
        })
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.title')}"></isceco-title>

      <isceco-switch id="module-paket"
                     label="${this.i18n.translate('module.paket')}"
                     ?value="${this.paketActive}"
                     @change="${e => this.updatePackage('paket', e)}"
      ></isceco-switch>
      <isceco-switch id="module-inpec"
                     label="${this.i18n.translate('module.inpec')}"
                     ?value="${this.inpecActive}"
                     @change="${e => this.updatePackage('inpec', e)}"
      ></isceco-switch>
      <isceco-switch id="module-traces"
                     label="${this.i18n.translate('module.traces')}"
                     ?value="${this.tracesActive}"
                     @change="${e => this.updatePackage('traces', e)}"
      ></isceco-switch>

      <isceco-filter-group>
        <isceco-date-filter
          label="${this.i18n.translate('table.header.dateTime')}"
          key="dateTime">
        </isceco-date-filter>
        <isceco-string-filter
          minchars="2"
          label="${this.i18n.translate('table.header.byUser')}"
          key="byUser">
        </isceco-string-filter>
        <isceco-multiselect-filter
          .items="${ModuleEnum.FILTER_ITEMS_NON_EMPTY()}"
          .i18n="${this.i18n}"
          label="${this.i18n.translate('table.header.module')}"
          key="module">
        </isceco-multiselect-filter>
        <isceco-checkbox-filter
          label="${this.i18n.translate('table.header.active')}"
          key="active">
        </isceco-checkbox-filter>
      </isceco-filter-group>

      <isceco-list id="module-history-table"
                   update-event="${this.updateTableEvent}"
                   url="${this.url}"
                   max="5"
                   sort="dateTime"
                   .i18n="${this.i18n}"
                   .dtoMapper="${this.toModuleHistory}"
                   .columns="${ModuleHistoryColumns.getColumns()}"
      ></isceco-list>
    `
  }

  toModuleHistory = row => new ModuleHistoryDTO(row)

  updatePackage(name, event) {
    const moduleService = new ModuleService()
    moduleService.create({active: event.detail.value}, `/${name}`)
      .then(_ => {
        const translationKey = event.detail.value ? 'module.success.activated.text' : 'module.success.deactivated.text'
        const type = event.detail.value ? 'success' : 'warning'
        showAlert(
          this.i18n.translate('module.success.title'),
          this.i18n.translate(translationKey, {name: event.target.label}),
          type
        )
        send(this.updateTableEvent)
      })
  }

}
customElements.define('eltra-management-setting', Module)
