export default class ApprovalEnum {
  // @alican Object freeze und als const
  static KEYS() {
    return {
      ACCEPTED: 0,
      REJECTED: 1,
      CANCELLED: 2
    }
  }

  static ITEMS() {
    return Object.entries(ApprovalEnum.KEYS())
      .map(([key, value]) => ({
        value: key,
        name: `approval.${value}`
      }))
  }
}
