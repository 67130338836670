export default class ApprovalTypeEnum {
  // @alican Object freeze und als const
  static KEYS() {
    return {
      HARD_APPROVAL: 0,
      SOFT_APPROVAL: 1,
      WHITE_LIST: 2,
      CANCELLATION: 3
    }
  }

  static ITEMS() {
    return Object.entries(ApprovalTypeEnum.KEYS())
      .map(([key, value]) => ({
        value: key,
        name: `approvalType.${value}`
      }))
  }
}
