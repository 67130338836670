export default class OverviewDTO {
  constructor(json) {
    if (json) {
      this.json = json.json
      this.requestId = json.requestId
      this.responseId = json.responseId
      this.goodsItemId = json.goodsItemId
      this.permitId = json.permitId
      this.transactionTime = json.transactionTime
      this.dispatchCountryCode = json.dispatchCountryCode
      this.permitNumber = json.permitNumber
      this.commodityCode = json.commodityCode
      this.netMass = json.netMass
      this.additionalUnit = json.additionalUnit
      this.customsOfficeNumber = json.customsOfficeNumber
      this.transportMode = json.transportMode
      this.selectionCode = json.selectionCode
      this.correctionCode = json.correctionCode
      this.approval = json.approval
      this.canton = json.canton
      this.approvalType = json.approvalType
      this.module = json.module
      this.ruleErrors = json.ruleErrors
      this.traderDeclarationNumber = json.traderDeclarationNumber
    } else {
      this.json = {}
    }
  }
}
