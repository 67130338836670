import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'

import AbfragenService from './AbfragenService.js'
import OverviewColumns from './OverviewColumns.js'
import OverviewDTO from './OverviewDTO.js'
import ModuleEnum from './ModuleEnum.js'
import ApprovalTypeEnum from './ApprovalTypeEnum.js'
import ApprovalRowVariant from './ApprovalRowVariant.js'

export default class Overview extends WebComponent {

  get translationFile() {
    return './views/Overview/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.reload()
  }

  reload() {
    const abfragenService = new AbfragenService()

    Promise.all([abfragenService.getBackendListUrl(), abfragenService.list('cantons'), abfragenService.list('errors')])
      .then(([url, cantons, errors]) => {
        this.url = url
        this.cantons = cantons.map(canton => ({
          value: canton,
          name: canton
        }))
        this.errors = errors.map(error => ({
          value: error,
          name: error
        }))
        this.render()
      })
  }


  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.title')}"></isceco-title>

      <isceco-filter-group>
        <isceco-date-filter label="${this.i18n.translate('filter.transactionTime')}"
                            key="transactionTime">
        </isceco-date-filter>
        <isceco-multiselect-filter .items="${ModuleEnum.FILTER_ITEMS()}"
                                   .i18n="${this.i18n}"
                                   label="${this.i18n.translate('filter.module')}"
                                   key="module">
        </isceco-multiselect-filter>
        <isceco-string-filter minchars="2"
                              label="${this.i18n.translate('filter.permitNumber')}"
                              key="permitNumber">
        </isceco-string-filter>
        <isceco-string-filter minchars="2"
                              label="${this.i18n.translate('filter.commodityCode')}"
                              key="commodityCode">
        </isceco-string-filter>
        <isceco-multiselect-filter .items="${this.cantons}"
                                   label="${this.i18n.translate('filter.canton')}"
                                   key="canton">
        </isceco-multiselect-filter>
        <isceco-string-filter minchars="2"
                              label="${this.i18n.translate('filter.customsOfficeNumber')}"
                              key="customsOfficeNumber">
        </isceco-string-filter>
        <isceco-multiselect-filter .items="${ApprovalTypeEnum.ITEMS()}"
                                   .i18n="${this.i18n}"
                                   label="${this.i18n.translate('filter.approvalType')}"
                                   key="approvalType">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter .items="${[{value: '0', name: '0'}, {value: '1', name: '1'}]}"
                                   label="${this.i18n.translate('filter.selectionCode')}"
                                   key="selectionCode">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter .items="${[{value: '0', name: '0'}, {value: '1', name: '1'}]}"
                                   label="${this.i18n.translate('filter.correctionCode')}"
                                   key="correctionCode">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter .items="${this.errors}"
                                   label="${this.i18n.translate('filter.ruleErrors')}"
                                   key="ruleErrors">
        </isceco-multiselect-filter>
        <isceco-string-filter minchars="2"
                              label="${this.i18n.translate('filter.traderDeclarationNumber')}"
                              key="traderDeclarationNumber">
        </isceco-string-filter>
      </isceco-filter-group>
      <isceco-list id="overview-table"
                   url="${this.url}"
                   max="5"
                   sort="transactionTime"
                   .i18n="${this.i18n}"
                   .rowurl="${dto => this.detailUrl(dto)}"
                   .dtoMapper="${this.toOverview}"
                   .columns="${OverviewColumns.getColumns()}"
                   .mobileView="${this.renderMobile}"
                   .mobileCss="${this.mobileCss()}"
                   .rowVariant="${new ApprovalRowVariant()}"
      ></isceco-list>
    `
  }

  mobileCss() {
    return css`
      .mobile-overview-item > *:not(:first-child) {
        margin-top: var(--isceco-space-200);
      }

      .mobile-overview-item > * {
        margin: 0;
      }
    `
  }

  renderMobile = (instance, row) => {
    const items = {}
    instance.columns
      .forEach(column => {
        items[column.key] = column.renderCell(this.i18n, row[column.key], row)
      })
    return html`
      <div class="mobile-overview-item">
        <p>
          <b>${items.transactionTime}</b>,
          ${this.i18n.translate('mobile.item.from')}
          <b>${items.dispatchCountryCode}</b>
        </p>
        <p>
          ${this.renderMobileItem(row, items, 'permitNumber')}
          ${this.renderMobileItem(row, items, 'commodityCode')}
        </p>
        <p>
          ${this.renderMobileItem(row, items, 'netMass')}
          ${this.renderMobileItem(row, items, 'additionalUnit')}
        </p>
        <p>
          ${this.renderMobileItem(row, items, 'customsOfficeNumber')}
          ${this.renderMobileItem(row, items, 'transportMode')}
          ${this.renderMobileItem(row, items, 'selectionCode')}
          ${this.renderMobileItem(row, items, 'correctionCode')}
          ${this.renderMobileItem(row, items, 'approval')}
          ${this.renderMobileItem(row, items, 'canton')}
        </p>
      </div>
    `
  }

  renderMobileItem(row, items, key) {
    const name = this.i18n.translate(`table.header.${key}`)
    return isEmpty(row[key]) ? html`` : html`
      ${name} <b>${items[key]}</b><br>
    `
  }

  toOverview = row => new OverviewDTO(row)

  detailUrl = dto =>
    `Detail?requestId=${dto.requestId}&responseId=${dto.responseId}&goodsItemId=${dto.goodsItemId}&permitId=${dto.permitId}`
}
customElements.define('eltra-management-landing', Overview)
