import Authorization from './Authorization.js'

export default class AuthorizationFilter {

  constructor() {
    this.roles = {
      betrieb: window.hasRole(Authorization.ROLES().betrieb),
      admin: window.hasRole(Authorization.ROLES().admin)
    }
  }

  viewsAllowed(names) {
    return names.filter(name => this.viewAllowed(name))
  }

  viewAllowed(name) {
    switch (name) {
      case 'LogSettings':
        return this.roles.betrieb
      case 'Module':
      case 'Overview':
      case 'Detail':
        return this.roles.admin
      case '':
        return this.roles.admin || this.roles.betrieb
      default:
        return false
    }
  }
}
