export default class ModuleHistoryDTO {
  constructor(json) {
    if (json) {
      this.json = json.json
      this.dateTime = json.dateTime
      this.byUser = json.byUser
      this.module = json.module
      this.active = json.active
    } else {
      this.json = {}
    }
  }
}
