import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'
import ApprovalEnum from './ApprovalEnum.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js'
import NumberCell from '@isceco/widget-library2/basic-elements/List/cell/NumberCell.js'

export default class OverviewColumns {

  static getColumns() {
    return [
      OverviewColumns.TRANSACTION_TIME_COLUMN(),
      OverviewColumns.DISPATCH_COUNTRY_COLUMN(),
      OverviewColumns.PERMIT_NUMBER_COLUMN(),
      OverviewColumns.TRADER_DECLARATION_NUMBER_COLUMN(),
      OverviewColumns.COMMODITY_CODE_COLUMN(),
      OverviewColumns.NET_MASS_COLUMN(),
      OverviewColumns.ADDITIONAL_UNIT_COLUMN(),
      OverviewColumns.CUSTOMS_OFFICE_NUMBER_COLUMN(),
      OverviewColumns.TRANSPORT_MODE_COLUMN(),
      OverviewColumns.SELECTION_CODE_COLUMN(),
      OverviewColumns.CORRECTION_CODE_COLUMN(),
      OverviewColumns.APPROVAL_COLUMN(),
      OverviewColumns.CANTON_COLUMN()
    ]
  }

  static TRANSACTION_TIME_COLUMN() {
    return new GenericColumn({
      key: 'transactionTime',
      text: 'table.header.transactionTime',
      cell: new DateTimeCell(),
      sortable: true,
      weight: 2
    })
  }

  static DISPATCH_COUNTRY_COLUMN() {
    return new StringColumn({
      key: 'dispatchCountryCode',
      text: 'table.header.dispatchCountryCode',
      sortable: true
    })
  }

  static PERMIT_NUMBER_COLUMN() {
    return new StringColumn({
      key: 'permitNumber',
      text: 'table.header.permitNumber',
      sortable: true,
      weight: 2
    })
  }

  static COMMODITY_CODE_COLUMN() {
    return new StringColumn({
      key: 'commodityCode',
      text: 'table.header.commodityCode',
      sortable: true
    })
  }

  static NET_MASS_COLUMN() {
    return new GenericColumn({
      key: 'netMass',
      text: 'table.header.netMass',
      cell: new NumberCell({digits: 3}),
      sortable: true
    })
  }

  static ADDITIONAL_UNIT_COLUMN() {
    return new GenericColumn({
      key: 'additionalUnit',
      text: 'table.header.additionalUnit',
      cell: new NumberCell({digits: 1}),
      sortable: true
    })
  }

  static CUSTOMS_OFFICE_NUMBER_COLUMN() {
    return new StringColumn({
      key: 'customsOfficeNumber',
      text: 'table.header.customsOfficeNumber',
      sortable: true
    })
  }

  static TRANSPORT_MODE_COLUMN() {
    return new StringColumn({
      key: 'transportMode',
      text: 'table.header.transportMode',
      sortable: true
    })
  }

  static SELECTION_CODE_COLUMN() {
    return new StringColumn({
      key: 'selectionCode',
      text: 'table.header.selectionCode',
      sortable: true
    })
  }

  static CORRECTION_CODE_COLUMN() {
    return new StringColumn({
      key: 'correctionCode',
      text: 'table.header.correctionCode',
      sortable: true
    })
  }

  static APPROVAL_COLUMN() {
    return new DropdownMultiselectColumn({
      key: 'approval',
      text: 'table.header.approval',
      items: Object.entries(ApprovalEnum.KEYS())
        .map(([key, value]) => ({
          value: key,
          name: `approval.${value}`
        })),
      sortable: true
    })
  }

  static CANTON_COLUMN() {
    return new StringColumn({
      key: 'canton',
      text: 'table.header.canton',
      sortable: true
    })
  }

  static TRADER_DECLARATION_NUMBER_COLUMN() {
    return new StringColumn({
      key: 'traderDeclarationNumber',
      text: 'table.header.traderDeclarationNumber',
      sortable: true,
      weight: 2
    })
  }
}
