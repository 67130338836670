import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'

export default class NotAuthorized extends WebComponent {

  get translationFile() {
    return './views/NotAuthorized/i18n.json'
  }

  get css() {
    return css`
      div {
        text-align: center;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.ssoAccessRequest = ''
    this.reload()
  }

  reload() {
    fetch('./config/config.json', {cache: 'reload'})
      .then(res => res.json())
      .then(config => {
        this.ssoAccessRequest = config.ssoAccessRequest
        this.render()
      })
  }

  getTemplate() {
    return html`
        <isceco-title text='${this.i18n.translate('page.title')}'></isceco-title>
        <div>
          ${this.i18n.translate('unauthorized')}
          <br/>
          <isceco-link url='${this.ssoAccessRequest}' text='${this.i18n.translate('page.title')}' ></isceco-link>
        </div>
    `
  }
}
customElements.define('eltra-management-not-authorized', NotAuthorized)
