import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Navigation from '../Navigation/Navigation.js'
import NoContent from '../NoContent/NoContent.js'
import Overview from '../Overview/Overview.js'
import Module from '../Module/Module.js'
import Detail from '../Detail/Detail.js'
import LogSettings from '../LogSettings/LogSettings.js'
import NotAuthorized from '../NotAuthorized/NotAuthorized.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import Authorization from '../Authorization/Authorization.js'

export default class MainView extends WebComponent {

  connectedCallback() {
    super.connectedCallback()
    this.oldChild = null
    this.navigationListener = e => {
      this.loadView(e.detail)
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.loadView(getNavigationHash())
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  loadView(linkName) {
    const newChild = this.returnNewChild(linkName)

    if (this.oldChild) {
      this.root.replaceChild(newChild, this.oldChild)
    } else {
      this.root.appendChild(newChild)
    }

    this.oldChild = newChild
  }

  returnNewChild(linkName) { //NOSONAR
    this.showHeaders(true)

    if (new AuthorizationFilter().viewAllowed(linkName)) {
      switch (linkName) {
        case 'Module':
          return new Module()
        case 'Detail':
          this.showHeaders(false)
          return new Detail()
        case 'Overview':
          return new Overview()
        case 'LogSettings':
          return new LogSettings()
        case '':
          return this.getDefaultView()
        default:
          return new NoContent()
      }
    } else {
      this.showHeaders(false)
      return new NotAuthorized()
    }
  }

  getDefaultView() { //NOSONAR
    if (window.keycloak && window.hasRole(Authorization.ROLES().betrieb)) {
      return new LogSettings()
    } else {
      return new Overview()
    }
  }

  getTemplate() {
    return html`eltra-management-main-view`
  }

  showHeaders(show) {
    document.querySelector('eltra-management-horizontal-navigation').style.display = show ? 'block' : 'none'
  }
}

customElements.define('eltra-management-main-view', MainView)
