import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import CheckboxColumn from '@isceco/widget-library2/basic-elements/List/column/CheckboxColumn.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'
import ModuleEnum from '../Overview/ModuleEnum.js'

export default class ModuleHistoryColumns {

  static getColumns() {
    return [
      ModuleHistoryColumns.DATE_TIME_COLUMN(),
      ModuleHistoryColumns.BY_USER_COLUMN(),
      ModuleHistoryColumns.MODULE_COLUMN(),
      ModuleHistoryColumns.ACTIVE_COLUMN()
    ]
  }

  static DATE_TIME_COLUMN() {
    return new GenericColumn({
      key: 'dateTime',
      text: 'table.header.dateTime',
      cell: new DateTimeCell(),
      sortable: true
    })
  }

  static BY_USER_COLUMN() {
    return new StringColumn({
      key: 'byUser',
      text: 'table.header.byUser',
      sortable: true
    })
  }

  static MODULE_COLUMN() {
    return new DropdownMultiselectColumn({
      key: 'module',
      text: 'table.header.module',
      items: Object.entries(ModuleEnum.KEYS())
        .map(([key, value]) => ({
          value: key,
          name: `module.${value}`
        })),
      sortable: true
    })
  }

  static ACTIVE_COLUMN() {
    return new CheckboxColumn({
      key: 'active',
      text: 'table.header.active',
      disabled: _ => true,
      sortable: true
    })
  }
}
